// CSS vars
:root {
  // breakpoints
  --sm: 600px;
  --md: 900px;
  --lg: 1200px;
  --xl: 1440px;
  --fhd: 1920px;
  --qhd: 2560px;
  --uwqhd: 3440px;
  --uhd: 3840px;

  // colors
  --gray-100: #e7e7e7;
  --gray-600: #666562;
  --brown-100: #f9f7f2;
  --brown-200: #f1eee6;
  --blue-100: #edf0f1;
  --blue-900: #002a3a;
}
