@tailwind base;
@tailwind components;
@tailwind utilities;

@import './variables.scss';

@layer base {
  * {
    padding: 0;
    margin: 0;
  }

  html {
    color: theme('colors.blue.900');
    font-size: 16px;
  }

  .xxs {
    font-size: 10px;
    line-height: 1.2; // 12px
  }

  .xs,
  .small-xs {
    font-size: 12px;
    line-height: 1.166667;
    font-family: var(--font-matter-regular), Arial, sans-serif;
  }

  small,
  .small,
  .body-sm {
    font-family: var(--font-matter-regular), Arial, sans-serif;
    font-size: 14px;
    line-height: 1.285714; // 18px
  }

  footer {
    font-size: 16px;
    line-height: 1.375; // 22px
    @screen md {
      font-size: 18px;
      line-height: 1.444444; // 26px
    }
  }

  body {
    font-size: 17px;
    line-height: 1.411765; // 24px
    font-weight: 300;
    font-family: var(--font-matter-regular), Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    max-width: 100vw;
    @screen md {
      font-size: 18px;
      line-height: 1.444444; // 26px
    }
  }

  h4,
  .h4 {
    font-size: 18px;
    line-height: 1.166667; // 21px
    font-family: var(--font-matter-regular), Arial, sans-serif;
    @screen md {
      font-size: 22px;
      line-height: 1.272727; // 28px
    }
  }

  h3,
  .h3 {
    font-size: 26px;
    line-height: 1.192308; // 31px
    font-family: var(--font-matter-regular), Arial, sans-serif;
    @screen md {
      font-size: 32px;
      line-height: 1.1875; // 38px
    }
  }

  h2,
  .h2 {
    font-size: 30px;
    line-height: 1.1; // 33px
    font-family: var(--font-matter-light), Arial, sans-serif;
    @screen md {
      font-size: 40px;
      line-height: 1.25; // 50px
    }
  }

  h1,
  .h1 {
    font-size: 34px;
    line-height: 1.176471; // 40px
    font-family: var(--font-matter-light), Arial, sans-serif;
    @screen md {
      font-size: 48px;
      line-height: 1.208333; // 58px
    }
  }

  h1.hero {
    font-size: 48px;
    line-height: 1.104167; // 53px
    font-family: var(--font-matter-light), Arial, sans-serif;
    @screen md {
      font-size: 64px;
      line-height: 1.109375; // 71px
    }
  }

  blockquote {
    font-size: 22px;
    line-height: 1.181818; // 26px
    font-family: var(--font-matter-light), Arial, sans-serif;
    @screen md {
      font-size: 40px;
      line-height: 1.25; // 50px
    }
  }

  b,
  strong {
    font-weight: 700;
    font-family: var(--font-matter-semibold);
  }

  u {
    text-decoration: underline;
  }

  em,
  i {
    font-style: italic;
  }

  .button-label {
    font-size: 15px;
    font-family: var(--font-matter-medium), Arial, sans-serif;
    line-height: 110%;
  }
}

@layer components {
  /* ----------------------------------------------------- */
  /* Container aligned in center with a max width of 1440px(xl)
  /* with side padding of 16px for mobile and 48px for min: 900px(md)
  /* ----------------------------------------------------- */
  .container {
    @apply max-w-screen-xl mx-auto px-4 md:px-12;
  }
  /* ------------------------------------------------------------------------------- */
  /* Grid Layout                                                                     */
  /* Mobile:  4 cols,  16px gap, 16px left/right padding                                   */
  /* Desktop: 12 cols, 48px side gap, 24px col gap - min: 900px(md), max: 1440px(xl) */
  /* ------------------------------------------------------------------------------- */
  .grid-container {
    @apply container grid grid-cols-4 md:grid-cols-12 gap-4 md:gap-6;
  }

  .small-full-width-column {
    @apply -mx-4 md:mx-0;
  }

  /* Buttons */
  .button-base {
    @apply button-label inline-block rounded-full py-[15px] px-[30px] my-4 text-center transition-all duration-300;
  }

  .button-light {
    @apply button-base bg-brown-100 hover:bg-blue-100 text-blue-900;
  }

  .button-dark {
    @apply button-base bg-blue-900 hover:bg-opacity-85 text-brown-100;
  }

  .button-outline {
    @apply button-base bg-transparent hover:bg-blue-100 text-blue-900 border-2 border-solid border-blue-900;
  }

  .button-outline-light {
    @apply button-base bg-transparent hover:bg-blue-100 text-brown-100 hover:text-blue-900 border-2 border-solid border-brown-100;
  }
}

@layer utilities {
  .animate-on-scroll {
    --scroll: 0;
  }

  .hero-image-animate {
    transform: translateY(calc(var(--scroll) / 2 * -1px));
  }

  .hero-content-animate {
    transform: translateY(calc(var(--scroll) * 1.2px));
  }
}

/* ----------------------------------------------------- */
/* HERO words animations */
/* Example:
  Say hello to
  <span class="switch-words">
    <strong class="switch">more energy</strong>
    <strong class="switch">better sleep</strong>
    <strong class="switch">more focus</strong>
    <strong class="switch">better mood</strong>
    <strong class="switch">your body</strong>
  </span>
/* ----------------------------------------------------- */
.switch-words {
  display: block;
  position: relative;
  overflow-y: hidden;
  height: 70px;

  --animation-time: 25s; // 5s * 5 items; 5s required to animate 1 word switch

  .switch {
    display: block;
    animation: switch var(--animation-time) infinite;
    position: absolute;
    opacity: 0;
  }

  .switch:nth-child(1) {
    animation-delay: 0s;
  }
  .switch:nth-child(2) {
    animation-delay: 5s;
  }
  .switch:nth-child(3) {
    animation-delay: 10s;
  }
  .switch:nth-child(4) {
    animation-delay: 15s;
  }
  .switch:nth-child(5) {
    animation-delay: 20s;
  }
}

@keyframes switch {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  5%,
  20% {
    transform: translateY(0%);
    opacity: 1;
  }
  25% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}


/* trust arc styles */
#consent-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 2100;
}